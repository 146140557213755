<template>
  <div :class="{ 'colapsed-header': isColapsedBlock }">
    <colapse-button
      :isColapsedBlock="isColapsedBlock"
      @click="isColapsedBlock = !isColapsedBlock"
    />
    <div
      v-if="!isColapsedBlock"
      class="head-and-filters js-head-and-filters"
      :style="`right: ` + rightHeadPosition"
    >
      <header-page :title="$route.name" :hasBackLink="false" />

      <div class="filters-container">
        <div class="filters-block">
          <div class="search">
            <input-text v-model="search_text" />
            <div
              v-if="isShowClear"
              class="main-button lg ml-15"
              @click="clearFilters"
            >
              <div>Clear</div>
            </div>
          </div>
          <div class="button">
            <div class="main-button lg orange" @click="isVisibleFilters = true">
              <div class="icon" v-html="Filter"></div>
              <div>Filters</div>
            </div>
          </div>
        </div>
        <div class="selected-filters" v-if="selectedFilters.length">
          <div
            class="selected-filters-groups"
            v-for="(group, key) in filterGroups"
            :key="key"
          >
            <div class="selected-filters-groups-item">
              <div
                class="icon"
                @click="removeFilterGroup(key)"
                v-html="Cross"
              ></div>
              <div class="text">{{ group[0].groupName }}</div>
              <div class="selected-filters-groups-item-sub-items">
                <template v-for="groupItem in group" :key="groupItem.text">
                  <div
                    v-if="!isArray(groupItem.text)"
                    class="selected-filters-item"
                  >
                    <div
                      class="icon"
                      @click="removeFilter(groupItem.id)"
                      v-html="Cross"
                    ></div>
                    <div class="text">
                      {{ groupItem.label }} {{ groupItem.text }}
                    </div>
                  </div>

                  <div
                    v-else
                    class="selected-filters-item"
                    v-for="groupItemArray in groupItem.text"
                    :key="groupItemArray"
                  >
                    <div
                      class="icon"
                      @click="
                        removeFilterArrayItem(groupItem.id, groupItemArray)
                      "
                      v-html="Cross"
                    ></div>
                    <div class="text">{{ groupItemArray }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      v-model:selectedRows="selectedRows"
      v-model:options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      :listTabs="listTabs"
      :listKey="listKey"
      @setTab="setTab"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewEntity"
      @saveRow="saveEntity"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      @exportSelectedCopyLink="exportSelectedCopyLink"
      @changedColumnsParams="changedColumnsParams"
      @importFile="uploadFile"
    />
    <set-filters
      v-if="isVisibleFilters"
      v-model="isVisibleFilters"
      :setFilterComponent="setFilterComponent"
      :filters="filters"
      @setFilters="setFilters"
      :groupToFilter="groupToFilter"
    />
  </div>

  <alert-yes-no
    v-if="isVisibleUploadAlert"
    title="Result"
    :text="contentUploadResult"
    :alertResult="alertUploadResult"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import { shallowRef } from 'vue'
import NewRowEntity from '@/components/NewRows/partner/NewRowLinkPartner'
import SetFilterComponent from '@/components/partner/SetFilters/SetFiltersLinksPartner'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { listMixin } from '@/utils/list-mixin'
import { hasAccess, permissions } from '@/utils/permissions'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

import CustomFooterComponent from '@/components/partner/CustomFooterPartnerLinks'
import numberToCommasString from '@/utils/text-formatters.js'

export default {
  name: 'LinksPartner',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    let filterKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isImport: hasAccess(permissions.import_links_from_csv),
        isSetColumns: true,
        isEditable: hasAccess(permissions.update_link),
        isExportToCSV: hasAccess(permissions.export_links_to_csv),
        isDelete: hasAccess(permissions.delete_links),
        addButtonText: 'Add link',
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          return `<div class="mb-10">
            Are you sure you want to delete link${rows.length > 1 ? 's' : ''}?
              ${rows
                .map(
                  (item) =>
                    `<div>• ${item.company_name}, ${item.link_builder}, ${item.url}, ${item.anchor}, ${item.target_page}, ${item.link_status}, ${item.date_published}</div>`
                )
                .join('')}
            </div>`
        },
        isCopyLink: true,
        customFooterComponent: {
          data: null,
          component: shallowRef(CustomFooterComponent),
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'id',
          text: 'Link ID',
          positionDefault: colsKeyUrlIndex,
          width: '80px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'partner_id',
          text: 'Partner ID',
          positionDefault: colsKeyUrlIndex,
          width: '80px',
        }),
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'company_name',
          text: 'Company name',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value, row }) => {
            if (value && row?.partner_id)
              return `<a href="${
                window.location.origin +
                '/link-builder-partner/' +
                row.partner_id
              }" target="_blank">${value}</a>`
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'partner_status',
          text: 'Partner status',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_builder',
          text: 'Link builder',
          positionDefault: colsKeyUrlIndex,
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'client_name',
          text: 'Client',
          positionDefault: colsKeyUrlIndex,
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'url',
          text: 'URL',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value }) => {
            if (value)
              return `<a href="${value}" title="${value}" target="_blank">${value}</a>`
            return ''
          },
          width: '300px',
          cssClass: 'bold-underline hand',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'anchor',
          text: 'Anchor',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value }) => {
            //if(typeof value === 'object' && value.length) return value.join(', ')
            return value
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'target_page',
          text: 'Target pages',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ value }) => {
            if (!value) return ''
            return `<a href="${value}" title="${value}" target="_blank">${value}</a>`
          },
          width: '300px',
          cssClass: 'bold-underline hand',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_status',
          text: 'Status',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'slack_community',
          text: 'Slack community',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ row }) => {
            if (row?.slack_community)
              return `
             <div class="checkbox-row checked">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
              <path d="M4 7L6 9L10 5" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </div>
             `
            else
              return `
              <div class="checkbox-row">
                <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
                </svg>
              </div>
              `
          },
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'loss_reason',
          text: 'Loss Reason',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_requested',
          text: 'Date requested',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_published',
          text: 'Date published',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_created',
          text: 'Date created',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'p2p',
          text: 'P2P',
          positionDefault: colsKeyUrlIndex,
          width: '75px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'commercial_pages_link',
          text: 'Content page',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'comments',
          text: 'Comments',
          positionDefault: colsKeyUrlIndex,
          width: '250px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_created_partner_card',
          text: 'Date of creation of the partner card',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'published_links_main',
          text: 'Published Links From Partner',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'pending_links_main',
          text: 'Pending links from Partner',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'pending_links_from_do',
          text: 'Pending Links From DO',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_gap',
          text: 'Link Gap',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'dr',
          text: 'DR',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'cost',
          text: 'Cost',
          positionDefault: colsKeyUrlIndex,
          width: '100px',
          formatedValue: ({ value }) => {
            let result = numberToCommasString(value)
            if (result !== 0 && !result) return value

            return `$${result}`
          },
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'date_status_changed',
          text: 'Date Status changed',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'rescued',
          text: 'Rescued',
          positionDefault: colsKeyUrlIndex,
          formatedValue: ({ row }) => {
            if (row?.rescued)
              return `
           <div class="checkbox-row checked">
            <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
            <path d="M4 7L6 9L10 5" stroke="white" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </div>
           `
            else
              return `
            <div class="checkbox-row">
              <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
               <rect x="0.5" y="0.5" width="13" height="13" rx="2.5"></rect>
              </svg>
            </div>
            `
          },
          width: '100px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'parser_status',
          text: 'Parser`s Status',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'slack_community_status',
          text: 'Slack Community Status',
          positionDefault: colsKeyUrlIndex,
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'created_by',
          text: 'Created by',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),

        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'modified_by',
          text: 'Last edited by',
          positionDefault: colsKeyUrlIndex,
          width: '150px',
        }),
      ],

      newRowComponent: shallowRef(NewRowEntity),
      setFilterComponent: shallowRef(SetFilterComponent),
      listKey: 'links-partner',
      groupToFilter: {
        date_created_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_created_f',
          name: 'Date created',
        },
        date_created_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_created_f',
          name: 'Date created',
        },
        date_published_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_published_f',
          name: 'Date published',
        },
        date_published_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_published_f',
          name: 'Date published',
        },
        link_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_status_f',
          name: 'Link status',
        },
        link_builder_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_builder_f',
          name: 'Link builder',
        },
        slack_community_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'slack_community_status_f',
          name: 'Slack Community status',
        },
        published_links_main_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_main_f',
          name: 'Published Links From Partner',
        },
        published_links_main_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'published_links_main_f',
          name: 'Published Links From Partner',
        },
        pending_links_main_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_main_f',
          name: 'Pending links from Partner',
        },
        pending_links_main_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_main_f',
          name: 'Pending links from Partner',
        },
        pending_links_from_do_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_from_do',
          name: 'Pending links from DO',
        },
        pending_links_from_do_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'pending_links_from_do',
          name: 'Pending links from DO',
        },
        link_gap_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_gap_f',
          name: 'Link Gap',
        },
        link_gap_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'link_gap_f',
          name: 'Link Gap',
        },
        partner_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'partner_status_f',
          name: 'Partner Status',
        },

        loss_reason_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'loss_reason_f',
          name: 'Loss Reason',
        },

        date_requested_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_requested_f',
          name: 'Date requested',
        },
        date_requested_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_requested',
          name: 'Date requested',
        },

        cost_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'cost_f',
          name: 'Cost',
        },
        cost_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'cost_f',
          name: 'Cost',
        },

        p2p_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'p2p_f',
          name: 'P2P',
        },

        date_status_changed_f_from: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_status_changed_f',
          name: 'Date Status changed',
        },
        date_status_changed_f_to: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'date_status_changed_f',
          name: 'Date Status changed',
        },
        parser_status_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'parser_status_f',
          name: 'Parser`s status',
        },
        slack_community_f: {
          keyUrl: 'f' + filterKeyUrlIndex++,
          key: 'slack_community_f',
          name: 'Slack community',
        },
      },
      activeTab: 'links-from-partner',
      contentUploadResult: '',
      isVisibleUploadAlert: false,
    }
  },

  created() {
    this.setDefaultColumnsParams() // 1
    this.setMetaDataColumnsParams() // 2
    this.setDataFromQueryParams() // 3
    this.fetchData()
  },

  methods: {
    alertUploadResult(/*result*/) {
      this.isVisibleUploadAlert = false
      this.contentUploadResult = ''
    },

    async uploadFile(refFile) {
      try {
        let file = refFile.files[0]

        this.$store.commit('setIsLoading', true)
        let formData = new FormData()
        formData.append('file', file)
        const token = localStorage.getItem('userAuth')
        let requestData = {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${JSON.parse(token).access_token}`,
          },
        }

        const response = await fetch(urls.importLinks, requestData)
        let result = await response.json()
        this.$store.commit('setIsLoading', false)

        if (result?.detail) {
          if (typeof result?.detail === 'string') {
            this.$notify({
              text: result?.detail,
              type: 'error',
              duration: 2000,
            })
          }
        } else {
          let arrayToDottedString = (arr) => {
            if (!arr || !arr.length) return ''

            return arr
              .map((item) => {
                return `<div>• ${item}</div>`
              })
              .join('')
          }

          let link_from_do_good = arrayToDottedString(result?.link_from_do_good)
          let link_from_partner_good = arrayToDottedString(
            result?.link_from_partner_good
          )
          let link_from_do_bad = arrayToDottedString(result?.link_from_do_bad)
          let link_from_partner_bad = arrayToDottedString(
            result?.link_from_partner_bad
          )

          let errors = arrayToDottedString(result?.errors)
          if (result?.template_link)
            errors += `<div><a href="${result?.template_link}" target="_blank">link to template</a></div>`

          this.contentUploadResult = `
               ${
                 link_from_do_good
                   ? '<h3>Link From DO <b style="color:green">(Good)</b></h3>'
                   : ''
               }
               ${link_from_do_good}

               ${
                 link_from_partner_good
                   ? '<h3 class="mt-20">Link from partner<b style="color:green">(Good)</b></h3>'
                   : ''
               }
               ${link_from_partner_good}

               ${
                 link_from_do_bad
                   ? '<h3 class="mt-20">Link From DO <b style="color:red">(Bad)</b></h3>'
                   : ''
               }
               ${link_from_do_bad}

               ${
                 link_from_partner_bad
                   ? '<h3 class="mt-20">Link from partner <b style="color:red">(Bad)</b></h3>'
                   : ''
               }
               ${link_from_partner_bad}
               ${errors ? '<h3>Errors</h3>' : ''}
               ${errors}
               `
          this.isVisibleUploadAlert = true
        }

        refFile.value = null
      } catch (e) {
        this.$notify({
          text: e,
          type: 'error',
          duration: 2000,
        })
        this.$store.commit('setIsLoading', false)
        refFile.value = null
      }
    },

    setTab(id) {
      if (!id) return

      this.activateTab(id)
      this.activeTab = id
    },

    saveNewEntity(entity) {
      this.setNewEntity(entity)
      this.addRow()
    },

    async editRow(entity) {
      this.$store.commit('setIsLoading', true)
      const id = entity.id
      delete entity.id

      let result = await fetchData(
        urls.links + `/${id}`,
        'POST',
        this.bodyToSave(entity)
      )
      if (!result?.detail) {
        let entityRow = this.rows.find((item) => item.id === id)

        for (const key in result) {
          if (key in entityRow) entityRow[key] = result[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.links,
        'POST',
        this.bodyToSave(this.newEntity)
      )
      if (!result?.detail) {
        const tmpEntity = this.newEntity
        this.rows.unshift({ ...{ id: result.id }, ...tmpEntity })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newEntityToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return

      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ''
        for (const id of ids) {
          query += `link_ids=${id}&`
        }

        return query
      }

      let query = ''

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += `${this.queryToFetch(1, 'all')}&${this.queryToFilters()}&${
          this.queryToSort() & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      let result = await fetchData(urls.links, 'DELETE', null, query)
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRowsCopyLink(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = '&'
        for (const id of ids) {
          query += `link_ids=${id}&`
        }

        if (query === '&') {
          for (const row of this.rows) {
            query += `link_ids=${row.id}&`
          }
        }

        if (this.options.isShowAllColumns) {
          query += `all_columns=true&`
        } else {
          query += `all_columns=false&`
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${this.queryToSort()}`
      } else {
        query += `links_from_team_f=partner`
      }

      query += exportQuery(ids)

      let result = await fetchData(
        urls.linksExportToCsvCopyLink,
        'GET',
        null,
        query
      )

      if (result?.csv_link) {
        this.copyText(result.csv_link)
      } else {
        this.$notify({
          text: 'Error',
          type: 'error',
          duration: 1000,
        })
      }

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = '&'
        for (const id of ids) {
          query += `link_ids=${id}&`
        }

        if (query === '&') {
          for (const row of this.rows) {
            query += `link_ids=${row.id}&`
          }
        }

        if (this.options.isShowAllColumns) {
          query += `all_columns=true&`
        } else {
          query += `all_columns=false&`
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += `${this.queryToFetch(
          1,
          'all'
        )}&${this.queryToFilters()}&${this.queryToSort()}`
      } else {
        query += `links_from_team_f=partner`
      }

      query += exportQuery(ids)

      await fetchCSV(urls.linksExportToCsv, 'GET', 'links', null, query)

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      this.$nextTick(() => {
        this.setHeightGridScrolableBlock()
      })
      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = ''
      let prevSearch_text = this.search_text.value

      query = `${this.queryToFetch()}&${this.queryToFilters()}&${this.queryToSort()}`

      let result = await fetchData(urls.links, 'GET', null, query)

      this.rows = result?.rows || []
      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)
      this.options.customFooterComponent.data = result?.totals || null

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },

    queryToSort() {
      let searchParams = new URLSearchParams()
      this.columns
        .filter((column) => !column.isHide && column.sortType)
        .forEach((column) => {
          if (column.key === 'status')
            searchParams.append('link_status', column.sortType)
          if (column.key === 'target_page')
            searchParams.append('target_url', column.sortType)
          else searchParams.append(column.key, column.sortType)
        })

      return searchParams.toString()
    },

    queryToFilters() {
      let searchParams = new URLSearchParams()
      for (const key in this.filters) {
        if (typeof this.filters[key] === 'object') {
          for (const value of this.filters[key]) {
            searchParams.append(key, value)
          }
        } else {
          searchParams.append(key, this.filters[key])
        }
      }

      switch (this.activeTab) {
        case 'links-from-partner':
          searchParams.append('links_from_team_f', 'partner')
          break
      }

      return searchParams.toString()
    },
  },
}
</script>
