<template>
  <div
    class="main-input select"
    ref="input"
    :class="{
      error: !inputModel.isValid,
      opened: isOpen,
      [inputModel.class]: inputModel.class,
    }"
  >
    <div class="input-body" @click="openList">
      <div class="margin-right22">
        <div class="formated-value" v-if="!inputModel.isTags">
          {{ selectedItemsString }}
        </div>
        <template v-else>
          <div class="tag" v-for="tag in tags" :key="tag.id">
            {{ tag.name }}
            <div
              class="unselect-tag"
              @click.stop="unselectItem(tag.id)"
              v-html="Cross"
            ></div>
          </div>
        </template>
        <div style="width: 100%" v-if="inputModel.isLiveSearch">
          <input
            type="text"
            class="filter-string"
            ref="inputControl"
            v-model="filterString"
            :readonly="inputModel.readonly"
          />
        </div>
      </div>
    </div>

    <teleport to="body">
      <ul
        v-if="isOpen"
        class="select"
        v-click-outside="onClickClose"
        :style="{
          top: top !== null ? top + 'px' : '',
          left: left + 'px',
          width: width + 'px',
        }"
      >
        <template v-for="group in groups" :key="group">
          <li @click="selectGroup(group)">
            <b>{{ group }}</b>
          </li>

          <li
            v-for="item in dataListFiltered.filter(
              (item) => item.group === group
            )"
            class="ml-15"
            :key="item.id"
            :class="{ selected: modelValue.value.includes(item.id) }"
            @click="selectItem(item.id)"
          >
            {{ item.name }}
          </li>
        </template>
      </ul>
    </teleport>

    <div
      class="clearable"
      v-if="!inputModel.readonly && inputModel.clearable && inputModel.value"
      @click.stop="clear"
      v-html="Cross"
    ></div>
    <div
      class="error-icon"
      v-if="!inputModel.isValid"
      v-html="InfoCircle"
    ></div>
    <div
      class="caret-down-icon"
      v-if="inputModel.isValid"
      v-html="CaretDown"
    ></div>
  </div>
</template>

<script>
import { inputMixin } from '@/utils/input-mixin'

import { CaretDown } from '@/utils/icons'
import vClickOutside from 'click-outside-vue3'
/*modelValue: {
      placeholder: 'Input select',
      value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
      isValid: true, // Валидно ли поле
      clearable: true, // Показывает значок очистки
      required: true, // Обязательность
      readonly: false, // Редактируемость
      isMultiselect: false, // Возможность множественного выбора 
      isTags: false, // Показывать в виде тегов
      isLiveSearch: false, // Показывать возможность фильтрации по строке
      dataList: [ // Данные для выпадающего списка
        { id: 1, name: 'id 1 text text text' },
        { id: 2, name: 'id 2 text' },
        { id: 3, name: 'id 3 text text' },
        { id: 4, name: 'id 4 text text' },
        { id: 5, name: 'id 5 text' },
        { id: 6, name: 'id 6 text' },
      ],
      class: ''
    }*/
export default {
  name: 'InputSelect',
  mixins: [inputMixin],
  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      CaretDown,
      isOpen: false,
      filterString: '',
      top: 0,
      left: 0,
      width: 0,
    }
  },

  created() {
    if (!this.inputModel.value) this.inputModel.value = []
  },

  computed: {
    selectedItemsString() {
      let value = ''
      if (this.inputModel.value) {
        if (!this.inputModel.readonly) {
          let list = this.inputModel.dataList.filter((item) =>
            this.inputModel.value.includes(item.id)
          )
          value = list.map((item) => item.name).join(', ')
        } else {
          value = this.inputModel.value.map((item) => item).join(', ')
        }
      }
      return value
    },

    dataListFiltered() {
      this.setPosition()
      return this.inputModel.dataList.filter(
        (item) =>
          item.name
            ?.toLocaleLowerCase()
            ?.indexOf(this.filterString?.toLocaleLowerCase()) === 0
      )
    },

    tags() {
      if (this.inputModel.value)
        return this.inputModel.dataList.filter((item) =>
          this.inputModel.value.includes(item.id)
        )
      else return []
    },

    groups() {
      const groups = this.inputModel.dataList.map((item) => item.group)
      return [...new Set(groups)]
    },
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.setPosition()
      }
    },

    filterString(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.isOpen = true
      }
    },
  },

  methods: {
    setPosition() {
      this.$nextTick(() => {
        this.top = null
        //let {scrollTop, scrollLeft} = this.$refs.input.closest('.scrolable-main-content')

        let { left, bottom, width, height } =
          this.$refs.input.getBoundingClientRect()
        //this.top = bottom + 4 + window.scrollY
        //this.left = left + window.scrollX
        const rowsCount = this.dataListFiltered?.length || 0

        // Значения магических чисел
        // 250 максимальная высота выпадашки
        // 4 отступ от инпута
        // 29 высота одного элемента в выпадашке
        // 10 бордеры и падинги выпадашки
        let topDropdown = bottom + 4
        if (topDropdown + 250 < document.body.clientHeight)
          this.top = topDropdown
        else {
          if (rowsCount < 11) {
            this.top = bottom - 4 - height - rowsCount * 29 - 10
          } else {
            this.top = bottom - 4 - height - 250
          }
        }

        this.left = left
        this.width = width < 120 ? 120 : width
      })
    },

    clear() {
      if (this.inputModel.isLiveSearch) this.filterString = ''
      this.inputModel.value = []
    },

    unselectItem(id) {
      let idx = this.inputModel.value.findIndex((item) => item === id)
      if (idx > -1) this.inputModel.value.splice(idx, 1)
      this.validate()

      this.$emit('changeValue', this.inputModel.value)
      if (!this.inputModel.isMultiselect) this.isOpen = false
      if (this.inputModel.isLiveSearch) this.filterString = ''
    },

    selectGroup(group) {
      const groupItems = this.inputModel.dataList.filter(
        (item) => item.group === group
      )

      const selectedGroupItems = groupItems.filter((item) =>
        this.inputModel.value.includes(item.id)
      )

      groupItems.forEach((item) => {
        this.selectItem(
          item.id,
          selectedGroupItems.length === groupItems.length ? false : true
        )
      })
    },

    selectItem(id, mustSelect = false) {
      if (this.inputModel.isMultiselect) {
        let idx = this.inputModel.value.findIndex((item) => item === id)
        if (idx === -1) this.inputModel.value.push(id)
        else if (!mustSelect) this.inputModel.value.splice(idx, 1)
      } else {
        let item = this.inputModel.value.find((item) => item === id)
        if (item) {
          this.inputModel.value.pop()
          this.$emit('changeValue', this.inputModel.value)
          return
        }
        this.inputModel.value.pop()
        this.inputModel.value.push(id)
      }
      this.validate()
      this.$emit('changeValue', this.inputModel.value)
      if (!this.inputModel.isMultiselect) this.isOpen = false
      if (this.inputModel.isLiveSearch) this.filterString = ''
    },

    onClickClose() {
      this.filterString = ''
      this.isOpen = false
    },

    openList() {
      if (this.inputModel.readonly) return

      this.filterString = ''
      this.isOpen = !this.isOpen
      if (this.inputModel.isLiveSearch) this.$refs.inputControl?.focus()
    },

    validate() {
      if (
        this.inputModel.required &&
        (!this.inputModel.value || !this.inputModel.value?.length)
      )
        this.inputModel.isValid = false
      else this.inputModel.isValid = true
    },
  },
}
</script>
